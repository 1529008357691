import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  sessions: [],
  selectedIndex: null, // Add your additional state here
};
const sessionSlice = createSlice({
  name: "Session",
  initialState,
  reducers: {
    setSessions: (state, action) => {
      state.sessions = action.payload;
    },
    setSelectedIndex: (state, action) => {
      state.selectedIndex = action.payload;
    },
  },
});
export const { setSessions, setSelectedIndex } = sessionSlice.actions;
export default sessionSlice.reducer;
