import { current } from "@reduxjs/toolkit";
import moment from "moment";

export const AUTH_TOKEN = "auth_token";
export const REFRESH_TOKEN = "refresh_token";
export const limitDropdown = [
  { name: "All Items", value: "all" },
  { name: "5 Items", value: 5 },
  { name: "10 Items", value: 10 },
  { name: "20 Items", value: 20 },
  { name: "30 Items", value: 30 },
  { name: "50 Items", value: 50 },
  { name: "100 Items", value: 100 },
];
export const userType = {
  company_manager: "COMPANY-MANAGER",
  company_administrator: "COMPANY-ADMINISTRATOR",
  admin: "ADMIN",
  company_employee: "COMPANY_EMPLOYEE",
};
export const userGuide = "USER-GUIDE";
export const userPolicy = "PRIVACY-POLICY";
export const statusDropdown = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "Active",
    value: 1,
  },
  {
    name: "Inactive",
    value: 2,
  },
];
export const statisticType = {
  comapny: "company",
  companyDashboard: "companyDashboard",
  admin: "admin",
  managerDashborad: "managerDashborad",
  viewInNineBox: "viewInNineBox",
};
export const noImageConstant = "../images/noUser.png";
export const noUserConstant = "../images/noUser.png";
// "https://9box.s3.us-east-2.amazonaws.com/storage/media/9box-Now-Default-User.png";
export const logo = "../images/logo.png";

export const groupType = {
  company: "company",
  manager: "manager",
  admin: "admin",
};

export const SessionStatusType = {
  expired: "Completed",
  current: "Current",
  upcomming: "Upcomming",
};

export const yearsDropdown = [
  {
    name: moment().year() - 1,
    value: moment().year() - 1,
  },
  {
    name: moment().year(),
    value: moment().year(),
  },
  {
    name: moment().year() + 1,
    value: moment().year() + 1,
  },
  {
    name: moment().year() + 2,
    value: moment().year() + 2,
  },
];

export const rateDelayMessage = {
  isBetween: "Success",
  isBetweenWithGracePeriod: "Rate is under grace period.",
  isAfter: "The evaluation submission deadline has passed.",
  isBefore: "The evaluation period hasn’t begun. Please try again later.",
};
